import logo from "./logo.svg";
import "./App.css";
import { useState } from "react";

function App() {
	const [name, setname] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setemail] = useState("");
	const [source, setsource] = useState("");
	const [lastcontacted, setLastcontacted] = useState("");
	const [concerened, setConcerened] = useState("");
	const [comments, setComments] = useState("");
	const [leadState, setLeadState] = useState("");
	const [api, setApi] = useState("");
	const [responsedata, setResponsedata] = useState({});

	const senddata = async () => {
		if (
			api != "" ||
			name != "" ||
			email != "" ||
			phone != "" ||
			source != "" ||
			lastcontacted != "" ||
			concerened != "" ||
			comments != "" ||
			leadState != ""
		) {
			const response = await fetch(
				"https://api-prodoc.dev.diginnovators.com/aws/v1/lead_aggregator",
				{
					headers: {
						"Content-Type": "application/json",
						"Api-Key": api
					},
					method: "POST",
					body: JSON.stringify({
						name: name,
						phone_number: phone,
						email: email,
						source: source,
						last_contacted_time: lastcontacted,
						concerned_doc: concerened,
						comments: comments,
						lead_state: leadState
					})
				}
			)
				.then((res) => res.json())
				.then((data) => {
					if (data.status == 200) {
						alert(data.message);
					} else alert(data.message);
				});
		} else {
			alert("Add all values");
		}
	};

	return (
		<div className="App">
			<h2>Zareen's Testing Portal</h2>
			<div className="inputcont">
				<label htmlFor="">API kEY:</label>
				<input
					type="text"
					value={api}
					onChange={(e) => {
						setApi(e.target.value);
					}}
				/>
			</div>
			<div className="inputcont">
				<label htmlFor="">Name:</label>
				<input
					type="text"
					value={name}
					onChange={(e) => {
						setname(e.target.value);
					}}
				/>
			</div>
			<div className="inputcont">
				<label htmlFor="">Phone No:</label>
				<input
					type="text"
					value={phone}
					onChange={(e) => {
						setPhone(e.target.value);
					}}
				/>
			</div>
			<div className="inputcont">
				<label htmlFor="">Email:</label>
				<input
					type="text"
					value={email}
					onChange={(e) => {
						setemail(e.target.value);
					}}
				/>
			</div>
			<div className="inputcont">
				<label htmlFor="">Source:</label>
				<input
					type="text"
					value={source}
					onChange={(e) => {
						setsource(e.target.value);
					}}
				/>
			</div>
			<div className="inputcont">
				<label htmlFor="">Last Contacted Time:</label>
				<input
					type="text"
					value={lastcontacted}
					onChange={(e) => {
						setLastcontacted(e.target.value);
					}}
				/>
			</div>
			<div className="inputcont">
				<label htmlFor="">Concerned Doctor:</label>
				<input
					type="text"
					value={concerened}
					onChange={(e) => {
						setConcerened(e.target.value);
					}}
				/>
			</div>
			<div className="inputcont">
				<label htmlFor="">Comments:</label>
				<input
					type="text"
					value={comments}
					onChange={(e) => {
						setComments(e.target.value);
					}}
				/>
			</div>
			<div className="inputcont">
				<label htmlFor="">Lead State:</label>
				<input
					type="text"
					value={leadState}
					onChange={(e) => {
						setLeadState(e.target.value);
					}}
				/>
			</div>
			<button
				onClick={() => {
					senddata();
				}}
			>
				Send Lead Data
			</button>
		</div>
	);
}

export default App;
